import React from 'react';
import { Layout } from '@components/global/Layout';
import { Link, PageProps } from 'gatsby';
import { RouteEnum } from '@model/route.enum';
import { Meta } from '@components/global/Meta';

const NotFound: React.FC<PageProps> = () => (
  <Layout
    title="404"
    navigationItems={[{ title: 'Zur Startseite', to: RouteEnum.INDEX }]}
  >
    <div className="flex">
      <div className="p-32">
        <p>Wir konnten den gewünschten Inhalt hier leider nicht finden.</p>
        <Link to={RouteEnum.INDEX}>
          <button type="button">Zur Startseite</button>
        </Link>
      </div>
      <h1 style={{ writingMode: 'vertical-rl' }}>Seite nicht gefunden.</h1>
    </div>
  </Layout>
);

export const Head = () => {
  return <Meta />;
};

export default NotFound;
